/*
  
  SASS Imports

*/

$breakpoints: (
  "mobile" "(max-width: 745px)",
  "tablet" "(min-width: 746px) and (max-width: 1023px)",
  "tablet-up" "(min-width: 746px)",
  "tablet-down" "(max-width: 1023px)",
  "desktop" "(min-width: 1024px)",
  "desktop-plus" "(min-width: 1200px)",
  "wide" "(min-width: 1400px)",
  "max" "(min-width: 2000px)",
  "shallow" "(min-width: 1024px) and (max-height: 1000px)",
  "very-shallow" "(min-width: 746px) and (max-height: 750px)",
  "fix-menu-footer" "(min-height: 850px)",
  "iphone5"
    "(min-width: 320px) and (max-width: 568px) and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 40/71) and (orientation: portrait)",
  "portrait" "(orientation: portrait)"
);

@import "./font/Doric/_font-face.scss";

@import "./sass/_mixins.scss";
@import "./sass/_animations.scss";

$spacing: 2.4rem;

* {
  box-sizing: border-box;
}

html,
body {
  font-family: "Doric News Headline Regular", Helvetica, Arial, sans-serif;
  color: #222;
  overflow: hidden;
  background: #fff;
  padding: 0;
  margin: 0;
  font-size: 16px;
  font-size: 1.55vh;
  line-height: 1.3;

  @include respond-to("mobile") {
    font-size: 16px;
  }
}

html,
body {
  overscroll-behavior-x: none;
}

h1 {
  font-size: 1.1rem;
  font-weight: normal;
  display: inline-block;
  line-height: 1;
  margin: 0;
  padding-left: 0px;
}

h2 {
  font-weight: normal;
  opacity: 0.5;
  font-size: 1.1rem;
  padding: 0;
  margin: 0;
  padding-bottom: 5px;
}

p {
  font-size: 1.1rem;
}

h3 {
  font-weight: normal;
  font-size: 0.66rem;
  padding: 0;
  opacity: 0.5;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  margin: 0;
  padding-bottom: 5px;
}

ul,
li {
  list-style: none;
  padding: 0;
  margin: 0;
}

body {
  transition: color 0.3s ease;
}

body.Body--index {
  color: #eee;

  a {
    color: inherit;
  }

  &.Body--project {
    color: #222;
  }
}

body.Body--loading {
  height: 100vh;

  cursor: wait;
  * {
    cursor: wait;
  }
}

a {
  color: inherit;
  text-decoration: none;
}

/*

Splash

*/

.Site-head {
  /*border-bottom:2px solid #eee;*/
}

.Site-title {
  position: fixed;
  text-align: center;
  top: $spacing;
  left: $spacing;
  font-size: 1.1rem;
  //letter-spacing: -0.03em;
  padding: 0;
  margin: 0;
  font-weight: 400;
  z-index: 5;

  a {
    text-decoration: none;
    color: #222;
    display: block;
  }
}

.Site-nav {
  position: fixed;
  text-align: left;
  bottom: $spacing;
  left: $spacing;
  font-size: 1rem;
  letter-spacing: -0.03em;
  padding: 0;
  margin: 0;
  font-weight: 400;
  z-index: 5;
  list-style: none;
  li {
    padding-top: 5px;
    cursor: pointer;
    &:first-child {
      padding-bottom: 20px;
    }
  }

  a {
    display: inline-block;
    position: relative;

    .Link-text {
      position: relative;
      z-index: 1;
    }
    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0px;
      right: -3px;
      bottom: 0;
      display: block;
      pointer-events: none;
      background: #222;
      /*background:rgba(150,150,150,0.7);
      backdrop-filter:blur(10px);*/
      border-radius: 15px;
      transform: scale(1.1);
      transition: all 0.4s ease;
      opacity: 0;
      z-index: 0;
    }
  }
  a.active {
    .Link-text {
      color: #eee;
    }

    &:after {
      opacity: 1;
      transform: scale(1.4);
    }
  }
}

.Site-profileLink {
  position: fixed;
  text-align: right;
  top: $spacing;
  right: $spacing;
  font-size: 1rem;
  letter-spacing: -0.03em;
  padding: 0;
  margin: 0;
  font-weight: 400;
  z-index: 5;
}

.Site-body {
  padding: $spacing;
  padding-top: 100px;
}

/*

Layers

*/

.Layer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.Layer--tiles {
  z-index: 0;
}

.Layer--profile {
  z-index: 4;
}

.Layer--project {
  z-index: 5;
}

/*

Page

*/

.Page,
.Page-scroll {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.Page-scroll {
  overflow-y: auto;
  z-index: 1;

  @include respond-to("mobile") {
    overflow-x: hidden;
  }
}

.Page-horiz-scroll {
  padding-top: 20px;
  padding-bottom: 100px;
  padding-left: 250px;
  white-space: nowrap;
  overflow-x: auto;
  transform: translateY(-20px);

  @include respond-to("mobile") {
    padding-left: $spacing;
    display: none;
  }
}

.Page-scroll-title {
  @include respond-to("mobile") {
    display: none;
  }
}

.Page-thumb {
  display: inline-block;
  padding-right: $spacing;
  white-space: normal;
  position: relative;

  a {
    display: block;
    opacity: 0.9;
    transition: opacity 0.4s ease;

    img {
      transition: all 0.4s ease;
    }

    &:hover {
      opacity: 1;
      img {
        transform: scale(1.01);
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
      }
    }
  }

  img {
    display: block;
    height: 150px;
    width: auto;
    border-radius: 10px;
    margin-bottom: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  }
}

.Page--project {
  .Page-paper {
    background: white;
    padding-top: 100px;

    box-shadow: 0 20px 50px rgba(0, 0, 0, 0.2), 0 50px 100px rgba(0, 0, 0, 0.5);

    .Page-blocks {
      opacity: 0;
      animation: scaleIn 0.8s 0.2s ease forwards;
      transform-origin: 50% 0;
    }
  }

  .Page-blackout {
    height: 100vh;
    background: black;
  }

  .Page-tiles {
    margin-left: -10px;
    text-align: center;

    li {
      display: inline-block;
      width: 33.333%;
      padding-left: 10px;
      vertical-align: top;
      padding-bottom: 10px;
    }
  }

  .Page-blocks--tiles {
    padding: 0 $spacing;
    min-height: 100vh;
  }

  .Page-tile {
    padding-top: 100%;
    position: relative;
    & > * {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .Page-close {
    position: absolute;
    top: 35px;
    top: 35/40 * $spacing;
    right: $spacing;

    //top: auto;
    //bottom: 30px;
    //right: auto;
    //left: 50%;
    //transform: translate(-50%, 0);

    z-index: 2;
    background: #333;
    width: 50px;
    height: 30px;
    border-radius: 20px;

    transition: transform 0.4s ease;
    background: rgba(150, 150, 150, 0.7);
    backdrop-filter: blur(10px);

    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      width: 20px;
      height: 20px;
      path {
        stroke: #eee;
      }
    }
  }

  .Page-title {
    font-size: 10px;
    text-transform: uppercase;
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    transform: translateY(-100%);
    font-weight: 300;
    letter-spacing: 0.05em;
  }

  .Page-description {
    position: absolute;
    top: $spacing;
    left: $spacing;
    z-index: 2;

    opacity: 0;
    animation: fadeUp 1s ease forwards;

    p {
    }

    * {
      padding: 0;
      margin: 0;
    }
  }
}

.Page--profile,
.Page--index,
.Page--home {
  background: #eee;

  &.Page--home {
    background: white;
  }

  .Page-title {
    display: none;
  }
  .Page-paper {
    padding-left: 250px;
    padding-top: $spacing;

    padding-bottom: 20px;

    padding-right: 100px;
    max-width: 1100px;

    opacity: 0;
    animation: scaleIn 0.8s 0.2s ease forwards;
    transform-origin: 50% 0;

    p {
      margin-top: 0;
      padding-top: 0;
    }

    @include respond-to("mobile") {
      padding: $spacing;
      padding-top: $spacing * 2.5;
    }
  }
}

.Page--home {
  .Page-paper {
    padding-top: 4.8rem;
  }
}

.Page--index {
  background: #222;
  color: #eee;

  font-size: 80px;
  font-weight: 200;

  .Page-bg {
    opacity: 0.5;
    background: black;
    & > * {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  a {
    text-decoration: none;
    color: #fff;
    transition: all 1s ease;
    opacity: 0.5;
    /*border-bottom:2px solid transparent;*/
    display: inline-block;
    position: relative;
  }

  a:hover {
    /*border-bottom:2px solid;*/
    opacity: 1;
    transition: all 0.7s ease;
  }
}

.Page--profile {
  background: transparent;

  &:after {
    content: "";
    display: block;
    width: 100%;
    height: 100px;
    background: red;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;

    background: linear-gradient(
      180deg,
      rgba(238, 238, 238, 1) 50%,
      rgba(238, 238, 238, 0) 100%
    );

    display: none;

    @include respond-to("mobile") {
      display: block;
    }
  }

  .Page-bg {
    background: #eee;
  }
  .Page-blackout {
    height: 100vh;
    background: black;
  }
}

/*

Home

*/

.Page--home {
  .Page-profile {
    background: #eee;
    min-height: 100vh;
  }
  .Page-reel {
    height: 100vh;
  }
  .Page-reel-media {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    pointer-events: none;
    z-index: -1;
    background: black;

    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .Page-reel-skip {
    position: absolute;
    bottom: 30px;
    left: 50%;
    color: white;
    mix-blend-mode: difference;
    svg {
      width: 20px;
      height: 20px;
      path {
        stroke: #eee;
      }
    }
  }

  .Page-sections {
    background: white;

    box-shadow: 0 -20px 50px rgba(0, 0, 0, 0.2),
      0 -50px 100px rgba(0, 0, 0, 0.5);
  }
}

/*

Profile

*/

.Profile p {
  margin-top: 0;
  padding-top: 0;
  font-size: 1rem;
}

.Profile-intro p {
  font-size: 1.4rem;
  margin-right: 0;
  padding-bottom: 20px;
  color: #222;
  //font-weight: 300;
}

.Profile a {
  color: #111;
  opacity: 0.5;
  transition: 0.4s ease;
}

.Profile a:hover {
  opacity: 1;
}

.Profile {
  .Content--contact {
    a {
      color: #222;
      opacity: 1;
      &:hover {
        opacity: 0.7;
      }
    }
  }
}
.Profile ul {
  padding-bottom: 20px;
}

.Profile li {
  padding-bottom: 0.4em;
}

.Profile-aside {
  padding: $spacing 0;
  display: flex;
  /*border-top:1px solid rgba(0,0,0,0.1);
  margin-top:$spacing;*/
  width: 75%;
  opacity: 0.7;

  @include respond-to("mobile") {
    width: 100%;
    padding-top: 1rem;
  }
}

.Profile-aside > div {
  padding-right: $spacing;
}

/*

Blocks

*/

.Blocks--inset {
  padding-left: $spacing;
  padding-right: $spacing;
}

.Blocks-grid {
  margin-left: -$spacing;
}

.Block {
  display: inline-block;
  vertical-align: top;
  width: 100%;
  padding-left: $spacing;
  padding-bottom: $spacing;
}

.Block--media {
  img,
  video {
    display: block;
    width: 100%;
  }
}

.Block--size-inset {
  padding-left: 290px;
}

.Block--size-half-grid {
  width: 50%;
}

.Block-caption {
  padding: 20px 0;
}

.Block--size-full .Block-caption {
  padding-left: $spacing;
}

.Block--size-inset {
  position: relative;
}
.Block--size-inset .Block-caption {
  position: absolute;
  top: 0;
  left: $spacing;
  bottom: $spacing;
  width: 250px;
  padding: 0px;
  box-sizing: border-box;

  span {
    display: block;
    position: sticky;
    top: 50%;
    left: 0;
    padding: $spacing;
    padding-right: 60px;
    padding-top: 0;
    padding-bottom: 0;
  }
}

/*

Burger

*/

.Burger {
  position: fixed;
  top: 35/40 * $spacing;

  @include respond-to("mobile") {
    transform: translateY(-20%);
  }
  right: $spacing;
  background: rgba(150, 150, 150, 0.7);
  backdrop-filter: blur(10px);

  z-index: 10;
  padding: 5px;
  border-radius: 20px;
  display: flex;
  width: 50px;
  height: 30px;
  justify-content: space-evenly;
  align-items: center;
  transition: all 0.4s ease;
  transform: translate3d(0, 0, 0);

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    width: 20px;
    height: 20px;
    path {
      stroke: #eee;
    }
  }
}

.Body--gallery .Burger {
  pointer-events: none;
  opacity: 0;
}

.Burger-text {
  display: none;
}

.Burger:hover {
  background: #212121;
  color: #fff;
  .Burger-el {
    background: white !important;
  }
}

@keyframes bounce {
  0% {
    transform: translateY(0) scaleY(1);
    background: #ddd;
  }

  10% {
    transform: translateY(80%) scaleY(0.8);
    background: #fff;
  }

  20% {
    transform: translateY(0%) scaleY(1);
    background: #ddd;
  }
}

.Burger-el {
  width: 5px;
  height: 5px;
  border-radius: 4px;
  background: #111;
  display: block;
  animation: bounce 3s ease infinite;
}

.Burger-el--2 {
  animation-delay: 0.1s;
}

.Burger-el--3 {
  animation-delay: 0.3s;
}

/*

DragWall

*/

[data-draggable] {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  touch-action: none;
}

.DragWall {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent;
  position: fixed;
  touch-action: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: default;
  //cursor: url("https://github.com/chenglou/react-motion/raw/master/demos/demo8-draggable-list/cursor.png") 39 39, auto;
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
  transform: scale3d(0.99, 0.99, 1);
  opacity: 0;
  //will-change: opacity,transform;

  &.DragWall--visible {
    opacity: 0;
    animation: scaleIn 0.8s 0.2s ease forwards;
  }

  &.DragWall--hidden {
    opacity: 1;
    animation: fadeOut 0.4s 0s ease forwards;
  }

  &:after {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    //background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 25%, rgba(255,255,255,0) 75%, rgba(255,255,255,1) 100%);
    content: "";
    pointer-events: none;
    opacity: 0.5;
  }
}

/*

Tile

*/

.Tile {
  height: 100%;
  width: 100%;
  position: relative;
  padding: 0 0;
  opacity: 0;
  animation: fadeIn 0.8s 0.2s ease forwards;
  transform: translate(-20%, -20%);
  &.Tile--noTransition {
    animation: none;
    opacity: 1;
  }

  display: block;

  & > div {
    width: 100%;
  }

  .Tile-link {
    display: block;
    pointer-events: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #222;
    width: $spacing;
    height: $spacing;
    border-radius: $spacing;
    color: #fff;
    transition: opacity 0.6s ease;
    opacity: 0;
    svg {
      width: 50%;
      height: 50%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      path {
        stroke: #fff;
      }
    }
  }

  &:hover {
    .Tile-link {
      opacity: 1;
      pointer-events: all;
    }
  }

  .Tile-image {
    width: 100%;
    height: 100%;
    position: absolute;

    overflow: hidden;
    pointer-events: none;

    & > * {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: 100%;
      object-fit: contain;
      transform: translate(-50%, -50%);
    }

    &.Tile-image--hidden {
      opacity: 0;
    }
  }

  a {
    display: block;
  }
  img {
    //width:100%;

    //border-radius:5px;
    //display:block;
    //margin:20px auto;
    //box-shadow:0 0 50px rgba(0,0,0,0.2);
  }

  .FocusImage {
    /*transition:all 0.8s cubic-bezier(0.33, 1, 0.68, 1);;
    width:50%;
    margin:0 auto;
    position:absolute;
    top:50%;
    left:50%;
    transform:translate(-50%,-50%);
    &.FocusImage--active{
      
      width:100%;
      box-shadow: 0 10px 50px rgba(0,0,0,0.2),0 20px 100px rgba(0,0,0,0.2);

    }*/
    position: absolute;
    width: 75%;
    height: 75%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow: hidden;
  }
}

.Highlighter {
  .FocusImage {
    //transform:translate3d(-50%,-50%,0);
    img {
    }
  }
}

/*

Swiper

*/

.swiper-container {
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .swiper-pagination {
    display: flex;
    justify-content: center;
    max-width: 100%;
    position: absolute;
    width: 98%;
    padding: 7px 5px 5px;
    align-self: center;
    z-index: 99;
    transition: opacity 1s ease;

    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.2) 50%,
      rgba(0, 0, 0, 0.7) 100%
    );

    background: linear-gradient(
      180deg,
      rgba(38, 38, 38, 0.25) 0%,
      rgba(38, 38, 38, 0) 100%
    );

    //background: transparent;

    width: 100%;
    padding: 10px 10px;
    height: 40px;

    line-height: 1;
    border-radius: 10px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    position: absolute;
    left: 50%;
    top: 0;
    bottom: auto;
    transform: translateX(-50%);
    opacity: 0;

    .swiper-pagination-bullet {
      height: 2px;
      max-width: 100%;
      background: rgb(85, 85, 85);
      margin: 2px;
      border-radius: 2px;
      transition: opacity 400ms ease-in-out 0s;
      width: 100%;
      opacity: 1;
      display: block;
      height: 2px;
      //background: #ccc;
      background: #fff;
      opacity: 0.5;
    }

    .swiper-pagination-bullet-active {
      background: #fff;
      opacity: 1;
      //background: #ccc;
      //opacity: 0.8;
      //box-shadow: 0 0 20px rgba(38, 38, 38, 1);
    }
  }
  .swiper-button-prev,
  .swiper-button-next {
    height: 100%;
    top: 0;
    width: 50%;
    &:after {
      display: none;
    }
  }
}

.StorySwiper--maximised {
  .swiper-slide-active .swiper-pagination {
    opacity: 1;
  }
}

.TapSwiper--1 {
  .swiper-pagination {
    display: none !important;
  }
}

.TapSwiper {
  .swiper-slide {
    width: 100% !important;
  }
}

/*

Tooltip

*/

.Tooltip {
  position: fixed;
  pointer-events: none;
  z-index: 100;
  transition: opacity 0.2s ease;
  opacity: 0;

  width: 80px;
  height: 60px;
  border-radius: 30px;

  background: rgba(180, 180, 180, 0.05);
  backdrop-filter: blur(10px);

  transform: translate(-50%, -50%);
  transform-origin: 50% 50%;

  mix-blend-mode: exclude;

  svg {
    width: 50px;
    height: 50px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    path {
      stroke: #111;
    }
  }

  .is-touch & {
    display: none !important;
  }
}

.Body--cursor-active {
  cursor: none;
  * {
    cursor: none;
  }
  .Tooltip {
    opacity: 1;
  }
}

.Body--cursor-state-prev {
  .Tooltip {
    transform: translate(-50%, -50%) rotate(-180deg);
  }
}

.Body--cursor-state-down {
  .Tooltip {
    transform: translate(-50%, -50%) rotate(90deg);
  }
}

/*

Slice 

*/
.Slice {
  height: 100vh;
  position: relative;
}

.Slice--window {
  height: 100vh;
  position: relative;
}
.Slice--break {
  position: absolute;
  width: 100vw;
  height: 100vh;
  clip: rect(0, auto, auto, 0);

  .Slice-media {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    pointer-events: none;
    z-index: 0;

    .Slice-media-frame {
      width: 100%;
      height: 100%;
      background: black;
      & > * {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      &.Slice-media-frame--cover {
        & > [data-rel="iframe"] {
          position: absolute;
          top: 0%;
          left: 0%;
          object-fit: none;
          right: auto;
          bottom: auto;
          width: 100%;
          height: 100%;

          // Assume iframe embeds are 16:9 videos

          @media (min-aspect-ratio: 16/9) {
            /* height = 100 * (9 / 16) = 56.25 */
            //height: 56.25vw;
          }
          @media (max-aspect-ratio: 16/9) {
            /* width = 100 / (9 / 16) = 177.777777 */
            //width: 177.78vh;
          }
        }
      }

      &.Slice-media-frame--contain {
        & > * {
          object-fit: contain;
        }
      }
    }
  }
}
.Slice--carousel {
  background: white;
  position: relative;
  z-index: 2;

  @include respond-to("mobile") {
    padding-top: $spacing * 2;
    min-height: 100vh;
    height: auto;
  }
  .Slice-body {
    min-width: 195px;
    width: 24vh;
    z-index: 9;
    position: sticky;
    top: 35%;
    left: 0;
    padding: $spacing;
    padding-bottom: 150px;
    padding-right: 10px;

    * {
      line-height: 1.3;
    }
    h2,
    p {
      margin: 0;
      padding: 0;
    }

    @include respond-to("mobile") {
      width: auto;
      position: relative;
      top: auto;
      left: auto;
      padding: $spacing;
    }
  }

  .Slice-icons {
    width: 195px;
    position: sticky;
    top: calc(100% - #{((90/40) * $spacing)});
    left: 0;
    padding: $spacing;
    padding-right: 10px;
    z-index: 10;

    @include respond-to("mobile") {
      display: none;
    }

    svg {
      width: 24px;
      //height:18px;
    }

    & > div.Slice-icon {
      display: inline-block;
      padding-right: 5px;
      position: relative;
    }

    a {
      opacity: 0.5;
      transition: opacity 0.5s ease;
      display: inline-block;
      position: relative;
    }

    a span {
      display: inline-block;
      position: absolute;
      bottom: 100%;
      margin-bottom: 10px;
      left: 50%;
      transform: translate(-50%, 20%);
      background: rgba(0, 0, 0, 0.2);
      padding: 5px 10px;
      border-radius: 20px;
      font-size: 10px;
      white-space: nowrap;
      opacity: 0;
      transition: all 0.4s ease;
    }

    a:hover {
      opacity: 1;

      span {
        transform: translate(-50%, 0%);
        opacity: 1;
      }
    }
  }

  .Slice-scrub {
    display: block;
    width: calc(100vw - 280px);
    top: -$spacing * 0.75;
    left: 100%;
    margin-left: 30px;
    position: absolute;
    z-index: 10;
    background: rgba(180, 180, 180, 0.7);
    backdrop-filter: blur(10px);
    padding: 8px 10px;
    border-radius: 10px;
    white-space: nowrap;
    overflow: hidden;
    line-height: 0;
    transition: all 0.4s ease;

    opacity: 0;
    pointer-events: none;
    transform: translateY(15px);

    &.active {
      opacity: 1;
      transform: none;
      pointer-events: all;
    }

    .Slice-scrub-track {
      position: relative;
    }
    .Slice-scrub-thumb {
      height: 60px;
      line-height: 0;
      display: inline-block;
      width: 100px;
      background-color: black;
      background-position: left center;
      background-size: auto 100%;
      background-repeat: repeat-x;
      opacity: 0.5;

      transition: opacity 0.6s ease;
      &:hover {
        opacity: 1;
      }

      &:first-child {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
      }
      &:last-child {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
      }
    }
  }

  .Slice-media {
    .Slice-media-foot {
      position: absolute;
      bottom: 30px;
      left: $spacing;
      right: $spacing;
      height: 60px;
      z-index: 11;
      top: auto;
    }

    position: absolute;
    top: 0px;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 8;

    @include respond-to("mobile") {
      position: relative;
      top: auto;
      bottom: auto;
      right: auto;
      left: auto;
      height: 40vh;
      min-height: 400px;
    }

    & > * {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }

    .Slice-object {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      & > * {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      &.Slice-object--cover {
        & > iframe {
          position: absolute;
          top: 50%;
          left: 50%;
          width: 100vw;
          height: 100vh;
          transform: translate(-50%, -50%);

          // Assume iframe embeds are 16:9 videos

          @media (min-aspect-ratio: 16/9) {
            /* height = 100 * (9 / 16) = 56.25 */
            height: 56.25vw;
          }
          @media (max-aspect-ratio: 16/9) {
            /* width = 100 / (9 / 16) = 177.777777 */
            width: 177.78vh;
          }
        }
      }

      &.Slice-object--contain {
        & > * {
          object-fit: contain;
        }
      }
    }

    .Swiper-next,
    .Swiper-prev {
      position: absolute;
      top: 0;
      height: 100%;
      width: 50%;
      left: 0;

      z-index: 10;

      .is-touch & {
        display: none;
      }
    }

    .Swiper-next {
      right: 0;
      left: auto;
    }

    &.Slice-media--contain {
      .swiper-slide img {
        object-fit: contain;
      }
    }
  }

  &.Slice--inset {
    .Slice-media {
      left: 210px;
      right: $spacing;
      right: 0;

      @include respond-to("mobile") {
        left: auto;
        right: auto;
      }
    }
  }
}
.StorySwiper {
  position: fixed;
  top: 0%;
  left: 0;
  right: 0;
  bottom: 0;

  cursor: grab;
  z-index: 4;
  transition: background 0.4s ease;
  .Story {
    transform: translateY(12%);
    transform: translateY(9vh);
  }

  & > .swiper-wrapper > .swiper-slide {
    width: auto;
    transition: transform 0.5s ease, opacity 1s ease;
    transform: scale(0.95);
    opacity: 0.2;

    &.swiper-slide-prev,
    &.swiper-slide-next {
      opacity: 0.4;
    }

    &.swiper-slide-active {
      transform: scale(1);
      opacity: 1;
    }
  }
}
.Story {
  position: relative;
  width: 40vh;
  height: 80vh;
  max-height: 500px;
  margin: 5vh auto;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1), 0 0 30px rgba(0, 0, 0, 0.1),
    0 0 50px rgba(0, 0, 0, 0.2);

  //
  max-height: 600px;
  height: 70vh;

  .Iframe-loader {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 5px;
    height: 5px;
    background: white;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    animation: fadeInOutPulse 2s ease infinite;
  }
  iframe {
    pointer-events: none;
  }
  & > div {
    overflow: hidden;
    border-radius: 10px;
    background: transparent !important;
  }

  [data-rel="iframe"] {
    background: black;
  }

  & > div {
    width: 100% !important;
    height: 100% !important;

    & > div:nth-child(2) {
      width: 100% !important;
      height: 100% !important;
    }

    & > div:nth-child(1) {
      overflow: hidden;
    }
  }
  .Story-object {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    overflow: hidden;
    border-radius: 10px;

    & > * {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .Story-object--contain {
    & > * {
      object-fit: contain;
    }
  }

  .Story-caption {
    z-index: 3;

    opacity: 0;
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: auto;
    z-index: 5;
    color: white;
    padding: 10px;
    font-size: 12px;
    padding-top: 60px;
    padding-bottom: $spacing * 3;
    padding-bottom: 30px;

    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.7) 0%,
      rgba(0, 0, 0, 0.5) 50%,
      rgba(0, 0, 0, 0) 100%
    );

    * {
      font-size: 14px;
      line-height: 1.4;
    }

    background: linear-gradient(
      180deg,
      rgba(38, 38, 38, 0) 0%,
      rgba(38, 38, 38, 0.6) 100%
    );

    border: 0;
    bottom: 0;
    box-sizing: border-box;

    font: inherit;
    font-size: 100%;
    height: 150px;
    left: 0;
    margin: 0;
    margin-top: auto;
    padding: 0 16px 16px;
    padding-top: 60px;
    padding-bottom: 160px;
    pointer-events: none;
    position: absolute;

    width: 100%;
    z-index: 999;
    bottom: 0px;
    position: absolute;
    padding: 0;
    opacity: 0;

    h2 {
      opacity: 1;
      text-align: left;
      text-shadow: 0 0 5px rgba(38, 38, 38, 0.5);
      position: absolute;
      bottom: 0;
      left: 0;
      line-height: 30px;
      padding: 30px 16px;
    }

    p {
    }

    .Content {
      height: 60px;
      overflow: hidden;
      text-overflow: ellipsis;
      opacity: 0.7;
      display: none;
      * {
        margin: 0;
      }
      max-width: 450px;
      display: none;
    }
  }
}

.Page-close.Page-close--stories {
  top: auto;
  bottom: 30px;
  right: auto;
  left: 50%;
  transform: translate(-50%, 0);
}

.StorySwiper--maximised {
  background: #111;
  z-index: 99;
  .Story {
    transform: translateY(0);
  }
  .swiper-slide-active {
    .Story-caption {
      transition: opacity 1s 0.2s ease;
      opacity: 1;
    }
  }
  .Story {
    width: 100vw;
    height: 100vh;
    height: var(--win-height);
    margin: 0;
    max-height: none;
  }
  .swiper-pagination,
  .Story,
  .Story > div,
  .Story-object {
    border-radius: 0;
  }
}

.Page-close {
  position: fixed;
  //bottom: $spacing;
  //left: 50%;
  //transform: translateX(-50%);
  z-index: 999;

  background: #333;
  width: 50px;
  height: 30px;
  border-radius: 20px;

  transition: transform 0.4s ease;
  background: rgba(150, 150, 150, 0.7);
  backdrop-filter: blur(10px);

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    width: 20px;
    height: 20px;
    path {
      stroke: #eee;
    }
  }
}

/*

Masonry 

*/

.Page-ratio {
  position: relative;
  overflow: hidden;
  border-radius: 12px;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);
  & > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  & > div:first-child {
    position: relative;
    height: auto;
  }
}

.Page-masonry-tile {
  border-radius: 12px;
}

.Page-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -30px; /* gutter size offset */
  width: auto;
}
.Page-masonry-column {
  padding-left: 30px; /* gutter size */
  background-clip: padding-box;
}

/* Style your items */
.Page-masonry-column > div {
  /* change div to reference your elements you put in <Masonry> */
  background: grey;
  margin-bottom: 30px;
}
